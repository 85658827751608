






import DocumentDetailNotesPartial from '@/components/document/Notes.vue'

import { DocumentResource } from '@/models/documents/DocumentResource'
import { documentsModule }  from '@/store'
import { Component, Vue }   from 'vue-property-decorator'

@Component({
  components: {
    DocumentDetailNotesPartial
  }
})
export default class DocumentDetailNotes extends Vue {

  private message: string = ''
  private loading: boolean = false

  private get detail(): DocumentResource | null {
    return documentsModule.detail
  }

}
